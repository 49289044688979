import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "./Header";
import ScrollToTop from "./ScrollToTop";

export default function Layout() {
	return (
		<>
			<ScrollToTop />
			<Header />
			<main className="pt-16 min-h-screen flex flex-col max-w-7xl mx-auto main-bottom">
				<Outlet />
				<ToastContainer />
			</main>
		</>
	)
}
