
import { NavLink } from 'react-router-dom'
export default function ExternalLinks() {
  return (
    <div className='flex flex-row justify-center gap-2 text-xs md:text-sm'>
      <div className='flex gap-2 justify-center'>
        <NavLink key='refund policy' target="_blank" to="https://b3sciences.com/refunds-returns/">
          <div className='flex flex-col items-center text-center'>
            <img alt="refund-policy" src="./assets/images/refund-policy.png" className='w-[50px] md:w-[100px]' />
            <p>REFUND POLICY</p>
          </div>
        </NavLink>
        <NavLink key='warranty' target="_blank" to="https://b3sciences.com/warranty/">
          <div className='flex flex-col items-center text-center'>
            <img alt="refund-policy" src="./assets/images/band-warranty.png" className='w-[50px] md:w-[100px]' />
            <p>BANDS <br /> WARRANTY</p>
          </div>
        </NavLink>
      </div>
      <div className='flex gap-2 justify-center'>
        <NavLink key='customer support' target="_blank" to="https://b3sciences.com/contact/">
          <div className='flex flex-col items-center text-center'>
            <img alt="refund-policy" src="./assets/images/customer-support.png" className='w-[50px] md:w-[100px]' />
            <p>CUSTOMER <br /> SUPPORT</p>
          </div>
        </NavLink>
        <NavLink key='privacy' target="_blank" to="https://b3sciences.com/privacy-policy/">
          <div className='flex flex-col items-center text-center'>
            <img alt="refund-policy" src="./assets/images/privacy-policy.png" className='w-[50px] md:w-[100px]' />
            <p>PRIVACY POLICY</p>
          </div>
        </NavLink>
      </div>
    </div>
  )
}