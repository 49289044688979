import { NavLink } from "react-router-dom"
import ListItem from "../../components/ListItem"

export default function B3AlertDetails() {
  return (
    <div>
      <ul>
        <ListItem>Delicious Black Cherry flavor</ListItem>
        <ListItem>Unique energy formula specifically designed with 15 ingredients that have been shown to enhance focus, brain power and energy levels.</ListItem>
        <ListItem>Vegan, Preservative Free, Gluten Free and Non-GMO</ListItem>
        <ListItem>30 SERVINGS</ListItem>
        <ListItem>MIX WITH WATER OR MAKE A FRUIT SMOOTHIE</ListItem>
        <ListItem>
          <NavLink key='for more info' target="_blank" to="https://b3sciences.com/alert/">
            <span className="uppercase">For more information on <span className="text-black">B</span>3 Alert go here(Link)</span>
          </NavLink>
        </ListItem>
      </ul>
    </div>
  )
}
