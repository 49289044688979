import React from 'react'
import { Dialog, Transition } from '@headlessui/react'

function CartProduct({ product, qty }) {
    let [isOpen, setIsOpen] = React.useState(false);
    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }
    return (<div className='grid grid-cols-8 text-base md:text-xl gap-4 border rounded-lg p-5 relative group' key={product.Sku}>
        <img alt={product.ProductName} src={`./assets/images/products/${product.Image}`} className="col-span-2" onClick={openModal} />
        <Transition appear show={isOpen} as={React.Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    {product.ProductName}
                                </Dialog.Title>
                                <img alt={product.ProductName} src={`./assets/images/products/${product.Image}`} className="w-full h-full mt-2" />
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
        <div className='col-span-4'>
            <p>{product.ProductName}</p>
            <div className='flex items-center sm:mt-0 mt-2'>
                <span className='text-base md:text-2xl mr-2'>QTY : </span>
                <input type="number" name="qty" id="qty" disabled value={qty} className="block border md:border-gray-400 border-[#00adef] px-2 py-0.5 md:py-1.5 w-[65px] text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#00adef] focus:outline-transparent sm:text-sm sm:leading-6" placeholder="Qty" />
            </div>
        </div>
        <div className=' col-span-2 text-right'>${(product.Price * qty).toFixed(2)}</div>
    </div>)
}

export default CartProduct